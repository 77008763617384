:root {
	--color-primary-100: #fbf0f2;
	--color-primary-200: #edb4bf;
	--color-primary-300: #dd6d83;
	--color-primary-400: #e7254a;
	--color-primary-500: #c60c30;
	--color-primary-600: #a00a27;
	--color-primary-700: #77071d;
	--color-primary-800: #570011;

	--color-secondary-100: #f5f0e9;
	--color-secondary-200: #eadfd2;
	--color-secondary-300: #d7c2a8;
	--color-secondary-400: #c9ad8b;
	--color-secondary-500: #bc996e;
	--color-secondary-600: #8d7353;
	--color-secondary-700: #6d5940;
	--color-secondary-800: #4d3f2d;

	--color-black: #000000;
	--color-gray-300: #fafafa;
	--color-gray-400: #f6f6f6;
	--color-gray-500: #d8d8d8;
	--color-gray-600: #666666;
	--color-gray-700: #333333;

	// payment banner
	--payment-description-background-color: #f5f0e9;
	--payment-description-instalments-background-color: #bc996e;

	// choice
	--choice-background-color: var(--color-gray-700);

	--color-text-loyalty: #d71920;
	--color-header-bottom-border: var(--color-gray-700);

	// active color
	--color-active: var(--color-gray-700);
	// payment banner
	--payment-description-instalments-background-color: var(--color-secondary-500);
	--payment-description-background-color: var(--color-secondary-100);

	// active color
	--color-active: var(--color-gray-700);

	// badge inclus
	--color-background-badge-inclus: var(--color-primary-500);
	--color-text-badge-inclus: white;

	// footer
	--color-background-footer: var(--color-gray-600);
	--color-text-footer: white;

	// push notification
	--color-background-push-notification: var(--color-gray-700);

	// timer
	--color-background-timer: var(--color-secondary-500);
	--color-text-timer: white;

	// input-label
	--input-label-active-color: var(--color-gray-700);

	//tabs
	--tab-active-color: var(--color-gray-700);
}
