/***************************
 * = COULEURS
 ***************************/

$color-primary: #333333;
$color-primary-dark: #201f1f;
$color-primary-light: #6b6969;
$color-secondary: #c60c30;
$color-secondary-dark: #a00a27;
$color-secondary-light: #c60c3040;
$black: #000000;
$grey-dark: #666666;
$grey-light: #f6f6f6;
$grey-medium: #d8d8d8;
$color-error: #a94442;
$color-valid: #0abf74;
$color-info: #3493dc;

/***************************
 * = FONTS
 ***************************/

%font-normal {
	font-family: Helvetica, sans-serif;
}

%font-bold {
	font-family: Helvetica, sans-serif;
	font-weight: bold;
}

%text {
	@extend %font-normal;
	font-size: 1.4rem;
	line-height: 1.5;
	color: $black;
}

%text-bold {
	@extend %font-bold;
	font-size: 1.4rem;
	line-height: 1.5;
	color: $black;
}

%text-secondary {
	@extend %font-normal;
	font-size: 1.4rem;
	line-height: 1.5;
	color: $grey-dark;
}

%text-secondary-bold {
	@extend %font-bold;
	font-size: 1.4rem;
	line-height: 1.5;
	color: $grey-dark;
}

%link {
	@extend %font-bold;
	font-size: 1.4rem;
	line-height: 1.5;
	color: $color-secondary;
	cursor: pointer;
	text-decoration: none;

	@include gridle_state(md) {
		&:hover {
			text-decoration: underline;
		}
	}
}

/***************************
 * = COMMUN
 ***************************/

$border-radius-small: 0px;
$border-radius-big: 3px;
$border-radius-top: $border-radius-big $border-radius-big 0 0;
$border-radius-bottom: 0 0 $border-radius-big $border-radius-big;
$footer-background: $grey-dark;
$footer-border: none;
$footer-text-color: white;
$header-background-color: $color-primary;
$header-border-bottom: none;
$header-menu-active-color: $color-secondary;
$header-text-color: white;
$header-text-transform: initial;
$account-aside-header-item-color: $black;

$auth-header-background-color: $color-primary;
$auth-header-mobile-background-color: white;
$auth-header-text-color: white;
$auth-header-mobile-text-color: $black;

$cookie-policy-footer-background: $black;
$cookie-policy-footer-color: white;
$cookie-policy-footer-text-transform: uppercase;
$phone-number-color: $color-secondary;

$hamburger-layer-color: white;
$common-box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);

$badge-background-color: $color-secondary;

$payment-banner-background-color: #bc996e;
$background-primary-gradient: linear-gradient(90deg, #8d6a3f 0%, #bc996e 100%);

/***************************
 * = FORM
 ***************************/

$active-color: $color-primary;
$active-color-dark: $color-primary-dark;
$active-color-light: $color-primary-light;
$input-border-color: $grey-medium;
$input-border: 1px solid var(--color-active, $active-color);
$input-border-radius: $border-radius-small;
$radio-checked-background: $grey-light;
$radio-hover-border-color: $active-color-light;
$radio-outline: none;
$radio-outline-error: none;
$input-shadow-box: 0 2px 3px 0 rgba(0, 0, 0, 0.1);
$button-primary-background-color: $color-secondary;
$button-primary-background-color-dark: $color-secondary-dark;
$button-box-shadow: none;

%master-button {
	border-radius: 5px;
}

%brand-button--secondary {
	box-shadow: 0 1px 2px 0 #33333340;
}

/***************************
 * = FICHE PRODUIT
 ***************************/
$product-accommodation-text-transform: uppercase;

$product-deal-text-color: white;

$offer-mobile-background-color: $color-primary;

$bloc-contact-title-case: uppercase;
$bloc-contact-body-background: $color-primary;
$bloc-contact-text-color: $black;

$product-aside-box-shadow: 0 2px 3px 0 rgba(0, 0, 0, 0.1);

$datepicker-background-color: $color-primary;

/***************************
 * = MOTEUR DE RECHERCHE
 ***************************/

$search-engine-offer-color: $black;

/***************************
 * = SMARTDP
 ***************************/

$smartdp-flash-sale-color: $color-primary;
$smartdp-search-marketing-color: $color-primary;
$smartdp-search-form-image-filter: rgba(0, 0, 0, 0.3);
$menu-account-menu-item-color: white;
$menu-account-submenu-item-color: white;

/***************************
 * = TUNNEL DE REZA | COMMUN
 ***************************/
$booking-bloc-title-main-background-color: $color-primary;
$booking-item-margin: 15px;
$booking-cta-background-color: #0abf74;
$booking-cta-background-color-dark: #088f57; // darken($booking-cta-background-color, 10%);
$quotation-price-detail-toggle-button-background: $black;
$quotation-price-detail-total-background: $black;
$quotation-price-detail-total-border: 1px solid $black;
$quotation-price-detail-total-font-color: white;
$quotation-price-detail-pay-now-border: 1px solid white;
$quotation-price-detail-text-color: white;
$quotation-price-detail-total-margin-bottom: 10px;

/***************************
 * = TUNNEL DE REZA | QUOTE
 ***************************/
$booking-badge-background-color: $color-secondary;
$badge-font-color: white;
$badge-border: none;
$badge-border-radius: $border-radius-big;

$quotation-filter-sort-background: white;

$flight-filter-button-background-color: $grey-light;
$flight-filter-button-color: $black;
$flight-filter-button-border-radius: $border-radius-big;
$flight-filter-background-color: $color-secondary;

$quotation-luggage-included-border-radius: $border-radius-small;
$quotation-luggage-included-outline: unset;

/***************************
 * = TUNNEL DE REZA | PAYMENT
 ***************************/
$insurances-radio-second-color: #6c6c6c;

/***************************
 * = TUNNEL DE REZA | CONFIRMATION
 ***************************/
$booking-confirmation-reference-color: $color-secondary;

/***************************
 * = SMARTDP
 ***************************/

/***************************
 * = LISTING
 ***************************/
$product-cta-background-color: $button-primary-background-color;
$product-cta-border: 1px solid $button-primary-background-color;
$product-cta-button-radius: 5px;
$product-deal-public-price-line-through-color: $black;
$product-intemporelle-color: $color-primary;
$listing-actionbar-title-display-on-mobile: block;
$listings-product-background-color: $grey-light;
$listing-header-height: 273px;
$colored-product-badge-background-color: $color-primary;
$colored-product-badge-text-transform: uppercase;
$colored-product-badge-border-radius: 5px;
$merchandising-simple-width: 100%;
$listing-time-border-radius: 50px;
$listing-time-background-color: $color-primary;

/***************************
 * = AUTH
 ***************************/
// auth form background that appears on a background that do not change (auth, booking/auth, home)
$auth-form-background-static: rgba(255, 255, 255, 0.13);
$auth-form-text-color-static: white;
$auth-form-box-shadow-static: 0px 1px 14px 0px rgba(0, 0, 0, 0.07);
$auth-form-border-color-static: rgba(255, 255, 255, 0.13);
// auth form background that appears on a background that can change over time (sign-up, landing product)
$auth-form-background-dynamic: rgba(0, 0, 0, 0.7);
$auth-form-text-color-dynamic: white;
$auth-marketing-message-shadow: none;
$auth-background-color: $black;
$auth-radial-gradient-primary: rgba(255, 255, 255, 0.2);
$auth-radial-gradient-secondary: rgba(255, 255, 255, 0);

/***************************
 * = MENU
 ***************************/

$menu-background-color: $color-primary;
$menu-text-color: white;

/***************************
 * = PLACEHOLDERS
 ***************************/

%booking-title {
	display: flex;
	align-items: center;
	justify-content: center;
	color: white;
	box-sizing: border-box;
	text-align: center;
	min-height: 40px;
	padding: 10px 0;
}

%input-label-touched {
	position: absolute;
	top: 0;
	left: 5px;
	font-size: 1rem;
	color: $black;
	background: none;
	padding-right: 10px;
	padding-left: 10px;
}

$listing-header-filter-max-width: none;
$listing-header-sticky-container-box-shadow: none;
$product-filter-header-box-shadow: none;
$products-filter-selected-border: none;
$products-master-filter-box-shadow: unset;
$aside-product-filters-menu-filters-title-height: 40px;

$marketing-slide-small-logo: url("/static/icons/icon-marketing-slide-small-logo-fr.svg");
$marketing-slide-small-1: url("/static/icons/icon-marketing-ek-1.svg");
