:root {
	--font-family: Helvetica, sans-serif;
	--font-family-bold: Helvetica, sans-serif;
	// default font-sizes are defined in _common-typography.scss. Override the default font sizes here.
	--font-size-xl9: 3.8rem;
	--font-size-xl8: 3.8rem;
	--font-size-xl7: 3.8rem;
	--font-size-xl6: 3.8rem;
	--font-size-xl5: 3.4rem;
	--text-transform-xl10: uppercase;
	--text-transform-xl9: uppercase;
	--text-transform-xl8: uppercase;
	--text-transform-xl7: uppercase;
	--text-transform-xl6: uppercase;
	--text-transform-xl5: uppercase;
	--font-size-bold-xl10: 4.2rem;
	--font-size-bold-xl9: 4.2rem;
	--font-size-bold-xl8: 4.2rem;
	--font-size-bold-xl7: 4.2rem;
	--font-size-bold-xl6: 3.8rem;
	--font-size-bold-xl5: 3.4rem;
	--text-transform-bold-xl10: uppercase;
	--text-transform-bold-xl9: uppercase;
	--text-transform-bold-xl8: uppercase;
	--text-transform-bold-xl7: uppercase;
	--text-transform-bold-xl6: uppercase;
	--text-transform-bold-xl5: uppercase;
	--font-weight-bold-xl10: normal;
	--font-weight-bold-xl9: normal;
	--font-weight-bold-xl8: normal;
	--font-weight-bold-xl7: normal;
	--font-weight-bold-xl6: normal;
	--font-weight-bold-xl5: normal;
	--font-weight-bold-xl4: bold;
	--font-weight-bold-xl3: bold;
	--font-weight-bold-xl2: bold;
	--font-weight-bold-xl: bold;
	--font-weight-bold-large: bold;
	--font-weight-bold-regular: bold;
	--font-weight-bold-small: bold;
	--font-weight-bold-xsmall: bold;
	--font-weight-bold-xxsmall: bold;
	--font-family-xl10: "heroic-condensed-medium-oblique", sans-serif;
	--font-family-xl9: "heroic-condensed-medium-oblique", sans-serif;
	--font-family-xl8: "heroic-condensed-medium-oblique", sans-serif;
	--font-family-xl7: "heroic-condensed-medium-oblique", sans-serif;
	--font-family-xl6: "heroic-condensed-medium-oblique", sans-serif;
	--font-family-xl5: "heroic-condensed-medium-oblique", sans-serif;
	--font-family-bold-xl10: "heroic-condensed-medium-oblique", sans-serif;
	--font-family-bold-xl9: "heroic-condensed-medium-oblique", sans-serif;
	--font-family-bold-xl8: "heroic-condensed-medium-oblique", sans-serif;
	--font-family-bold-xl7: "heroic-condensed-medium-oblique", sans-serif;
	--font-family-bold-xl6: "heroic-condensed-medium-oblique", sans-serif;
	--font-family-bold-xl5: "heroic-condensed-medium-oblique", sans-serif;
}

@media (min-width: $md) {
	:root {
		--font-weight-xl9: normal;
		--font-family-xl9: "heroic-condensed-medium-oblique", sans-serif;
		--text-transform-xl9: uppercase;
		--font-weight-bold-xl9: normal;
		--font-family-bold-xl9: "heroic-condensed-medium-oblique", sans-serif;
		--text-transform-bold-xl9: uppercase;

		--font-weight-xl5: normal;
		--font-family-xl5: "heroic-condensed-medium-oblique", sans-serif;
		--text-transform-xl5: uppercase;
		--font-weight-bold-xl5: normal;
		--font-family-bold-xl5: "heroic-condensed-medium-oblique", sans-serif;
		--text-transform-bold-xl5: uppercase;

		--text-transform-xl10: initial;
		--text-transform-xl8: initial;
		--text-transform-xl7: initial;
		--text-transform-xl6: initial;

		--text-transform-bold-xl10: initial;
		--text-transform-bold-xl8: initial;
		--text-transform-bold-xl7: initial;
		--text-transform-bold-xl6: initial;

		--font-size-xl10: 8rem;
		--font-size-xl9: 6.4rem;
		--font-size-xl8: 5.2rem;
		--font-size-xl7: 4.4rem;
		--font-size-xl6: 3.6rem;
		--font-size-xl5: 2.8rem;
		--font-size-xl4: 2.4rem;
		--font-size-xl3: 2.2rem;
		--font-size-xl2: 2rem;
		--font-size-xl: 1.8rem;
		--font-size-large: 1.6rem;
		--font-size-regular: 1.4rem;
		--font-size-small: 1.2rem;
		--font-size-xsmall: 1rem;
		--font-size-xxsmall: 0.8rem;

		--font-size-bold-xl10: 8rem;
		--font-size-bold-xl9: 6.4rem;
		--font-size-bold-xl8: 5.2rem;
		--font-size-bold-xl7: 4.4rem;
		--font-size-bold-xl6: 3.6rem;
		--font-size-bold-xl5: 2.8rem;
		--font-size-bold-xl4: 2.4rem;
		--font-size-bold-xl3: 2.2rem;
		--font-size-bold-xl2: 2rem;
		--font-size-bold-xl: 1.8rem;
		--font-size-bold-large: 1.6rem;
		--font-size-bold-regular: 1.4rem;
		--font-size-bold-small: 1.2rem;
		--font-size-bold-xsmall: 1rem;
		--font-size-bold-xxsmall: 0.8rem;
	}
}
